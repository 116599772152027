import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { pluralize } from '../../../../utils';

import CommentIcon from '../../../UI/CommentIcon/CommentIcon';
import Preloader from '../../../UI/Preloader/Preloader';

const CompaniesCompany = ({
  item: {
    id, full_name, user, comments, inn,
  },
  onCreateAct, onDelete,
}) => {
  const [isPDFFetching, setIsPDFFetching] = useState(false);
  const [isXLSXFetching, setIsXLSXFetching] = useState(false);

  const authUser = useSelector(({ auth }) => auth.user);

  const handleDelete = () => onDelete(id);

  const handleCreateActXLSX = () => {
    setIsXLSXFetching(true);

    onCreateAct(id, full_name, 'xlsx', () => setIsXLSXFetching(false));
  };

  const handleCreateActPDF = () => {
    setIsPDFFetching(true);

    onCreateAct(id, full_name, 'pdf', () => setIsPDFFetching(false));
  };

  return (
    <div className="company">
      <div className="company__info">
        <div className="company__name">
          <Link to={`/companies/edit/${id}`}>{full_name}</Link>
        </div>
        <div className="company__user-login">
          <Link to={`/users?search=${user.email}&search_type=full_email`}>{user.email}</Link>
        </div>
      </div>
      <div className="company__actions">
        {(comments && comments.length > 0) ? (
          <div className="company__comment">
            <Link to={`/companies/edit/${id}`}>
              <CommentIcon
                comment={`${comments.length} ${pluralize(comments.length, ['комментарий', 'комментария', 'комментариев'])}`}
              />
            </Link>
          </div>
        ) : null}
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn" disabled={false}>
            <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link to={`/invoices?query=${inn.trim()}&search_type=company_inn`}>
              <Dropdown.Item as="button" disabled={false} onClick={() => {}}>
                Найти счета
              </Dropdown.Item>
            </Link>
            <Link to={`/users?search=${user.email}&search_type=full_email`}>
              <Dropdown.Item as="button" disabled={false} onClick={() => {}}>
                Найти пользователя
              </Dropdown.Item>
            </Link>
            <Dropdown.Item as="button" disabled={isPDFFetching} onClick={handleCreateActPDF}>
              Акт сверки в PDF
              {isPDFFetching && <Preloader small />}
            </Dropdown.Item>
            <Dropdown.Item as="button" disabled={isXLSXFetching} onClick={handleCreateActXLSX}>
              Акт сверки в XLSX
              {isXLSXFetching && <Preloader small />}
            </Dropdown.Item>
            <Link to={`/companies/edit/${id}`}>
              <Dropdown.Item as="button" disabled={false} onClick={() => {}}>
                Редактировать контрагента
              </Dropdown.Item>
            </Link>
            {!authUser?.admin_permissions?.includes('support') && (
              <Dropdown.Item className="company__delete-btn" as="button" disabled={false} onClick={handleDelete}>
                Удалить контрагента
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

CompaniesCompany.propTypes = {
  item: PropTypes.object.isRequired,
  onCreateAct: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompaniesCompany;
